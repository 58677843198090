(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name home.matches.controller:MatchesCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.refereeNewInvoice.facturar')
    .controller('facturar', facturar);

  function facturar(AclService,invoicesgenerate,townHalls, $scope, fechaini,fechafin,ajuntament,ids) {
    var vm = this;
   
     var parameters = {
                initDate: fechaini,
                endDate: fechafin,
                isAdmin:AclService.isAdmin(),
                idAjuntament:ajuntament,ids:ids
                
      };
      
    invoicesgenerate.query(parameters);
     
    
   
       

  }
}());


